import axios from 'axios'
import Config from '../Config';

axios.defaults.baseURL = process.env.APP_EMBED_URL;
axios.defaults.headers.common['X-Embed-Version'] = process.env.APP_EMBED_HEADER;
axios.defaults.headers.common['X-Correlation-id'] = Config.get('correlation_id')

export const EMBED_URL = process.env.APP_EMBED_URL;

export const EMBED_BUNDLE_PATH = `${EMBED_URL}/static/bundles/`;

export default {
  /**
   * Fetch entry by id
   * @param type {string}
   * @param entry_id {string}
   * @param options {object}
   * @return {AxiosPromise<any>}
   */
  entry(type, entry_id, options = {}) {
    return axios.get(`/${type}/${entry_id}`, {
      params: {options: JSON.stringify(options)}
    })
  },
  /**
   * Mark video as played
   * @param video_id
   * @return {AxiosPromise<any>}
   */
  markAsPlayed(video_id) {
    return axios.post(`/video/${video_id}/played`)
  },
  /**
   * Fetch player by id
   * @param player_id {string}
   * @return {AxiosPromise<any>}
   */
  player(player_id) {
    return axios.get(`/player/${player_id}`)
  },
  /**
   * Get player bundle by player name
   * @param player {string}
   * @return {AxiosPromise<any>}
   */
  bundle(player) {
    return axios.get(`${EMBED_URL}/static/${player}`)
  }
}
