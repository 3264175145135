import Http from '../core/Http'
import Logger from '../core/Logger';

/** @class player*/
export default class player {

  /** @param player_id {string}*/
  constructor(player_id) {
    this.player_id = player_id
  }

  /**
   * Fetch player from api
   * @return {AxiosPromise<any>}
   */
  fetch() {
    this.__beforeFetch();
    return Http.player(this.player_id)
      .then(({data}) => {
        this.__afterFetch();
        return data
      })
      .catch(e => this.__handleError(e))
  }

  /**
   * Render the player
   * @param entry {object}
   */
  render(entry) {
    this.__beforeRender(entry)
  }

  __beforeFetch() {
    Logger.info(`Fetching a player by id [${this.player_id}]`)
  }

  __afterFetch() {
    Logger.info('Player request succeeded')
  }

  __beforeRender(entry) {
    const {type, title} = this.data
    Logger.info(`Rendering player [${title} / ${type}] for entry type [${entry.type}]`)
  }

  __handleError(error) {
    throw new Error('An error occur while fetching player')
  }
}
