import Config from './core/Config';
import ElementGenerator from './core/ElementGenerator';
import Player from './instances/Player'
import Entry from './instances/Entry'
import Factory from './players/Factory';
import Logger from './core/Logger';
import EventBus from './core/EventBus'
import {OnPlayerPlay} from './core/EventBus/Subscribers/OnPlayerPlay';
import {appendElement} from './helpers';
import ScreenGenerator from "./core/ScreenGenerator";

export default class Sdk {
  /** @param config {object}*/
  constructor(config) {
    Config.init(config);
    this.elementGenerator = new ElementGenerator(config.el);
    this.playerFactory = new Factory();
    this._registerSubscribers();
  }

  /**
   * @param player_id {string}
   * @return {Sdk}
   */
  player(player_id) {
    /** @type {player}*/
    this._player = new Player(player_id);
    return this
  }

  /**
   * @param video_id {string}
   * @param options {object}
   * @return {*}
   */
  video(video_id, options) {
    return this._createEntry(video_id, 'video', options)
  }

  /**
   * @param playlist_id {string}
   * @return {Sdk}
   */
  playlist(playlist_id) {
    return this._createEntry(playlist_id, 'playlist')
  }

  /**
   * @param callback
   * @return {Promise<T>}
   */
  render(callback) {
    const {_entry, _player, playerFactory, elementGenerator} = this;
    return _entry.fetch()
      .then(() => _player.fetch()
        .then((data) => {
          const {type} = data;
          const element = elementGenerator.generate(type);

          playerFactory.make(type, () => {
            Logger.info('Player successfully booted. Rendering player...');
            playerFactory.render(_entry, data, element, callback)
          })
        })).catch((e) => {
          if (e.code === 203) {
            // render error message
            const errorScreen = new ScreenGenerator(e.message)
            errorScreen.renderWarningPage()
          }
        })
  }

  /**
   * @param entry_id {string}
   * @param type {string}
   * @param options {object}
   * @return {Sdk}
   * @private
   */
  _createEntry(entry_id, type, options) {
    this._entry = new Entry(entry_id, type, options);
    return this
  }

  /**
   * Register basic subscribers on bus instance
   * @private
   */
  _registerSubscribers() {
    EventBus.on('player.play', new OnPlayerPlay())
  }
}
