import mapper from '../mappers/videojs'
import Logger from '../../core/Logger';
import Http from '../../core/Http';
import EventBus from '../../core/EventBus';
import {bundleLoaded, embedBundleScripts, embedIMA, embedMatomo} from '../../helpers';
import Config from "../../core/Config";

/** @type {string}*/
const BUNDLE_CLASS_NAME = 'hibrid-vod-videojs-player';

let attempts = 0;

const validateLoaded = (callback) => setTimeout(() => {
  if (window.videojs) return callback();
  if (attempts === 10) throw new Error('An error occur while booting videojs player');
  attempts++;
  return validateLoaded(callback);
}, 100);
/**
 * @param player
 * @param options {{}}
 * @param entry {{}}
 * @param sources {Array}
 */
const onPlayerInit = (player, options, entry, sources) => {
  // activate playlist plugin if entry type is playlist
  if (entry.type === 'playlist') {
    player.playlist(sources);
    player.playlist.autoadvance(0)
    player.playlistUi()
  }

  // activate quality selector for hls video
  player.hlsQualitySelector({
    displayCurrentQuality: true,
  });

  player.contextmenuUI({
    content: [{
      href: 'https://www.hibridmena.com/',
      label: 'Hibrid Media Platform'
    }]
  });

  player.seekButtons({forward: 10, back: 10});

  if (options.social) {
    options.social.url = options.social.url || window.location.href
    player.share(options.social);
  }

  if (options.advertising && window.google) {
    player.ima({
      id: 'video',
      adTagUrl: options.advertising.tag
    })
  }

  player.on('play', () => {
    EventBus.emit('player.play', player.currentSource())
  });

  player.on('error', (e) => {
    Logger.error('Player error');
    Logger.error(e)
  });
};

export default {
  /**
   * @param player {string}
   * @param callback {function}
   */
  boot(player, callback) {
    embedIMA();
    // embedMatomo(Config.get('mtm', ''));
    // in case if bundle was already injected into DOM
    // we should not embed it again
    if (bundleLoaded(BUNDLE_CLASS_NAME)) {
      Logger.info(`${BUNDLE_CLASS_NAME} already exist in DOM`);
      return Promise.resolve();
    }
    Logger.info('Loading a bundle for videos player');
    // fetching fresh bundle from API and embedding scripts into DOM
    return Http.bundle(player)
      .then(({data}) => {
        embedBundleScripts(data, BUNDLE_CLASS_NAME) && validateLoaded(callback)
      })
      .catch((e) => console.log(e))
  },
  /**
   * @param entry {{}}
   * @param attributes {{}}
   * @param element {HTMLElement}
   * @param callback {function}
   */
  render(entry, attributes, element, callback) {
    const sources = mapper(entry);
    const [{poster}] = sources;
    const options = {
      ...attributes.options, poster,
      advertising: attributes.advertisement_tag,
      // textTrackSettings: true
    };
    if (entry.type === 'video') options.sources = sources;
    const skin = `vjs-theme-${options.skin}`;

    Logger.info(`Rendering player with options ${JSON.stringify(options)}, skin [${skin}]`);

    element.classList.add(skin);

    const player = window.videojs(element, options, () => {
      callback ? callback(player) : null;
      try {
        if (entry.data && entry.data.subtitles) {
          console.log(entry.data.subtitles);
          entry.data.subtitles.forEach(item => {
            player.addRemoteTextTrack({
              kind: 'captions',
              src: process.env.APP_EMBED_URL + '/subtitles?url=' + item.url,
              label: item.title,
              srclang: item.language,
              language: item.language,
              mode: 'showing',
            })
          });
        }
      } catch (e) {
        console.log(e.toString())
      }
      Logger.info(`Videojs player rendered`);
    });
    onPlayerInit(player, options, entry, sources);
  }
}
